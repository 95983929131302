<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.returnable_packaging_write_off')">
            <CustomTable  ref="packagingTable" class="mt-1" :fields="columnDefs" api-url="/api/client/v1/returnable_packaging/write_off">
                <template #buttons v-if="$hasPermission($permissions.ReturnablePackagingWrite)">
                    <b-button variant="primary" @click="$refs.addModal.open()">
                        <feather-icon icon="PlusSquareIcon"/>
                        {{ $t('keys.returnable_packaging.add_write_off') }}
                    </b-button>
                </template>
                <template #date="item">
                    {{$dayjs(item.item.timestamp).format('DD.MM.YYYY')}}
                </template>
                <template #together_packaging="{item}">
                    {{ item.together_packaging / 100 }}
                </template>
                <template #actions="{item}">
                    <div class="d-flex justify-content-center">
                        <b-button class="mr-1 m-0 button" variant="danger" size="sm" @click="remove(item.id)">
                            {{ $t('general.delete') }}
                        </b-button>
                        <b-button class="mr-1 m-0 button" variant="warning" size="sm" @click="editRow(item)">
                            {{ $t('general.edit') }}
                        </b-button>
                    </div>
                </template>
            </CustomTable>
        </b-card>
        <returnable-packaging-write-off-modal ref="addModal" @itemAdded="$refs.packagingTable.loadData()"></returnable-packaging-write-off-modal>
    </b-overlay>

</template>
<script>
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import ReturnablePackagingWriteOffModal from '@/views/ReturnablePackagingWriteOff/components/ReturnablePackagingWriteOffModal.vue'
    import CustomTable from '@/components/CustomTable.vue'
    import {Formaters} from '@/libs/formaters'

    export default {
        components:{
            CustomTable,
            BCard,
            BOverlay,
            ReturnablePackagingWriteOffModal,
            BButton
        },
        data() {
            return {
                productsRaw: [],
                vats: [],
                showLoader: false
            }
        },
        methods: {
            editRow(data) {
                this.$refs.addModal.open(data, this.returnablePackagingType)
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/returnable_packaging/write_off/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.$refs.packagingTable.loadData()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            formatDate(inputDate) {
                return this.dayjs(inputDate.timestamp).format('DD.MM.YYYY')
            }
        },
        computed: {
            columnDefs() {
                const hasPermission =  this.$hasPermission(this.$permissions.ReturnablePackagingWrite)
                const defs = [
                    {
                        key: 'date',
                        label: this.$t('table_fields.date'),
                        filter: true,
                        permissions: true,
                        formatter: Formaters.formatDateOnly,
                        filterType: 'date'
                    },
                    {
                        key: 'different_packaging',
                        label: this.$t('table_fields.different_packaging_count'),
                        filter: false,
                        permissions: true
                    },
                    {
                        key: 'together_packaging',
                        label: this.$t('table_fields.total_packaging_count'),
                        filter: false,
                        permissions: true
                    }

                ]

                if (hasPermission) {
                    defs.push(
                        {
                            key: 'actions',
                            label: this.$t('table_fields.actions'),
                            permissions: true
                        }
                    )
                }

                return defs
            }
        }
    }
</script>